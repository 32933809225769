import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SigninModule } from './signin.module';
import { SigninEntryComponent } from './signin-entry.component';
import { RouterModule } from '@angular/router';
import { SIGNIN_ROUTES } from './signin.routes';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

@NgModule({
  declarations: [SigninEntryComponent],
  exports: [RouterModule],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    SigninModule,
    RouterModule.forChild([...SIGNIN_ROUTES]),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class SigninEntryModule {}
