import { SigninComponent } from './signin.component';
import { Routes } from '@angular/router';
import { AlreadyLoggedInResolver } from '../../resolvers/alreadyloggedin.resolver';
import { SigninEntryComponent } from './signin-entry.component';
import { RegisterComponent } from './register.component';
import { EAppRoutes } from '../../models';
import { ForgotPasswordComponent } from './forgot-password.component';

export const SIGNIN_ROUTES: Routes = [
  {
    path: '',
    component: SigninEntryComponent,
    resolve: {
      AlreadyLoggedInResolver,
    },
    children: [
      {
        path: '',
        component: SigninComponent,
      },
      {
        path: EAppRoutes.REGISTER,
        component: RegisterComponent,
      },
      {
        path: EAppRoutes.PASSWORD_RESET,
        component: ForgotPasswordComponent,
      },
    ],
  },
  {
    path: EAppRoutes.VALIDATE_EMAIL,
    loadChildren: () =>
      import('./validate-email/validate-email.module').then(
        ({ ValidateEmailModule }) => ValidateEmailModule,
      ),
  },
];
