<div class="mh-100 px-3 px-lg-0 pb-3 active" id="id-tab-signup">
  <!-- show this in desktop -->
  <div
    class="d-none d-lg-block col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-lg-4 px-0"
  >
    <h4 class="text-dark-tp4 border-b-1 brc-grey-l1 pb-1 text-130">
      <i class="fa fa-user text-purple mr-1"></i>
      Forgot Password
    </h4>
  </div>

  <!-- show this in mobile device -->
  <div class="d-lg-none text-secondary-m1 my-4 text-center">
    <a href="/">
      <i class="fa fa-leaf text-success-m2 text-200 mb-4"></i>
    </a>
    <h1 class="text-170">
      <span class="text-blue-d1">
        <span
          class="text-80 text-dark-tp3"
          [innerHtml]="'appName' | translate"
        ></span>
      </span>
    </h1>
    Forgot Password
  </div>

  <form [formGroup]="formGroup" class="form-row mt-4">
    <div
      class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <section class="mb-3">
        <sa-input
          type="email"
          formControlName="email"
          name="email"
          [label]="'email' | translate | titlecase"
        >
          <prefix>
            <fa-icon [icon]="faEmail"></fa-icon>
          </prefix>
          <custom-validator
            [controller]="formGroup.controls.email"
          ></custom-validator>
        </sa-input>
      </section>
    </div>

    <div
      class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <button
        (click)="onForgetPassword()"
        type="button"
        class="ripple btn btn-primary radius-0 w-100 p-2 btn-bold mt-2 mb-4 waves-effect waves-themed"
        [innerHTML]="'forgotPasswordBtn' | translate"
      ></button>
    </div>
  </form>

  <div class="form-row w-100">
    <div
      class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex flex-column align-items-center justify-content-center"
    >
      <hr class="brc-default-l2 mt-0 mb-2 w-100" />

      <div class="p-0 px-md-2 text-dark-tp4 my-3">
        Already a member?
        <a
          class="text-blue-d1 text-600 mx-1"
          routerLink="/"
          [innerHTML]="'secureLogin' | translate"
        >
        </a>
      </div>
      <hr class="brc-default-l2 w-100 mb-2" />
      <p
        class="blockquote text-300 text-success-d3 my-3"
        *ngIf="validate"
        [innerHTML]="'forgotPassword' | translate"
      ></p>
    </div>
  </div>
</div>
