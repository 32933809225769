import { inject, Injectable } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AppState } from '../app.service';
import { PermissionService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AlreadyLoggedInResolverService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private authorizationService: PermissionService,
    private appState: AppState,
  ) {}

  resolve(): Observable<any> | Promise<any> | any {
    return this.authorizationService.ping().pipe(
      map((d) => {
        this.appState.set('user', d);
        return d['email'];
      }),
      catchError((error: HttpErrorResponse) => of(false)),
    );
  }
}

export const AlreadyLoggedInResolver: ResolveFn<any> = () => {
  return inject(AlreadyLoggedInResolverService).resolve();
};
