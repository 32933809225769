<div class="mh-100 px-3 px-lg-0 pb-3" id="id-tab-login">
  <!-- show this in desktop -->
  <div class="d-none d-lg-block col-md-6 offset-md-3 mt-lg-4 px-0">
    <h4 class="text-dark-tp4 border-b-1 brc-secondary-l2 pb-1 text-130">
      <i class="fa fa-coffee text-orange-m1 me-1"></i>
      {{ "welcomeBack" | translate }}
    </h4>
  </div>

  <!-- show this in mobile device -->
  <div class="d-lg-none text-secondary-m1 my-4 text-center">
    <a href="/">
      <i class="fa fa-leaf text-success-m2 text-200 mb-4"></i>
    </a>
    <h1 class="text-170">
      <span class="text-blue-d1">
        <span
          class="text-80 text-dark-tp3"
          [innerHtml]="'appName' | translate"
        ></span>
      </span>
    </h1>

    {{ "welcomeBack" | translate }}
  </div>

  <form [formGroup]="formGroup" class="form-row mt-4">
    <div
      class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <section class="">
        <sa-input
          type="email"
          formControlName="email"
          name="email"
          id="email"
          [label]="'email' | translate | titlecase"
        >
          <prefix>
            <fa-icon [icon]="faEmail"></fa-icon>
          </prefix>
          <custom-validator
            [controller]="formGroup.controls.email"
          ></custom-validator>
        </sa-input>
      </section>
    </div>

    <div
      class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-2 mt-md-1"
    >
      <section class="mb-3">
        <sa-input
          formControlName="password"
          name="password"
          id="password"
          autocomplete="password"
          [inputClass]="'form-control-lg'"
          [type]="showPassword ? 'text' : 'password'"
          [label]="'password' | translate | titlecase"
        >
          <prefix>
            <fa-icon [icon]="faLock"></fa-icon>
          </prefix>
          <custom-validator
            [controller]="formGroup.controls.password"
          ></custom-validator>
        </sa-input>
        <div class="mt-2 justify-content-end d-flex align-items-center mute">
          <checkbox
            [(ngModel)]="showPassword"
            [ngModelOptions]="{ standalone: true }"
            >{{ "showPass" | translate }}
          </checkbox>
        </div>
      </section>
    </div>

    <div
      class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-right text-md-right mt-n2 mb-2"
    >
      <a
        [innerHTML]="'forgotPass' | translate"
        class="text-primary-m1 text-95 ripple cursor-pointer"
        [routerLink]="['password-reset']"
      ></a>
    </div>

    <div
      class="form-group col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <button
        type="button"
        class="ripple btn btn-primary radius-0 w-100 p-2 btn-bold mt-2 mb-4 waves-effect waves-themed"
        (click)="signIn()"
        [innerHTML]="'secureLogin' | translate"
      ></button>
    </div>
  </form>

  <div class="form-row w-100">
    <div
      class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex flex-column align-items-center justify-content-center"
    >
      <hr class="brc-default-l2 mt-0 mb-2 w-100" />

      <div class="p-0 px-md-2 text-dark-tp3 my-3">
        {{ "dontHaveAccount" | translate }}
        <a class="text-success-m1 text-600 mx-1" [routerLink]="['register']">
          {{ "signup" | translate }}
        </a>
      </div>

      <hr class="brc-default-l2 w-100 mb-2" />
    </div>
  </div>
</div>
