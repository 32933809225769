import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { V1_API } from '@app/environment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppState } from '../../app.service';
import { PermissionService } from '../../services';
import { GraphqlService } from '../../graphql';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SigninServices {
  constructor(
    private permissionService: PermissionService,
    private graphqlService: GraphqlService,
    private http: HttpClient,
    private appState: AppState,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
  ) {}

  flush(): Observable<any> {
    this.appState.remove('user');
    return isPlatformBrowser(this.platformId)
      ? this.http.get(`${V1_API}/flush`)
      : of(null);
  }

  forgotPassword({ email }) {
    return this.graphqlService.query({
      operation: 'forgotPassword',
      variables: {
        email: { value: btoa(email), required: true, type: 'Base64String' },
      },
    });
  }

  register({ email, terms }) {
    return this.graphqlService.query({
      operation: 'register',
      variables: {
        email: { value: btoa(email), required: true, type: 'Base64String' },
        terms: { value: terms, required: true, type: 'Boolean' },
      },
    });
  }

  authenticate({ email, password }): Observable<any> {
    return this.permissionService.authenticate(email, password).pipe(
      map(({ authenticated_client }) => {
        const user = { ...authenticated_client };
        this.appState.set('user', user);
        return user;
      }),
    );
  }
}
