<input
  type="checkbox"
  [attr.id]="id"
  [name]="name"
  (click)="onClicked($event)"
  #inputModel
  [ngClass]="{ 'is-invalid': ngModelCtrl.invalid && ngModelCtrl.dirty }"
  class="me-2"
  [(ngModel)]="_model"
  #ctrl="ngModel"
  [required]="required"
  [disabled]="readonly || disabled"
  (ngModelChange)="modelChanged($event)"
  [ngModelOptions]="{ standalone: true }"
/>
<label [attr.for]="id" #ref [class.d-none]="!ref.innerHTML">
  <ng-content></ng-content>
</label>
