import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EAppFullRoutes } from '../../models';
import { Subscription } from 'rxjs';
import { faLeaf } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: '[signin-entry]',
  styleUrls: ['./signin-entry.component.scss'],
  templateUrl: `./signin-entry.component.html`,
  host: {
    class: 'h-100',
  },
  standalone: false,
})
export class SigninEntryComponent implements OnInit {
  public spinnerIcon = faSpinner as IconProp;
  public loading: boolean = true;
  private subscriptions: Subscription = new Subscription();

  public footer: string = 'PonyLagoon &copy; 2023';

  public faLeaf = faLeaf as IconProp;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data.subscribe(({ AlreadyLoggedInResolver }) => {
        this.loading = !!AlreadyLoggedInResolver;
        if (this.loading) {
          const navigationExtras: NavigationExtras = {
            replaceUrl: true,
          };
          const command = [...EAppFullRoutes.LANDING];
          this.router.navigate(command, navigationExtras).then();
        } else {
          this.loading = false;
        }
      }),
    );
  }
}
