import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { CheckboxGroupComponent } from './checkbox.group.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [CheckboxComponent, CheckboxGroupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
  exports: [
    CheckboxComponent,
    CheckboxGroupComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CheckboxModule {
  entry = CheckboxModule;

  constructor() {}
}
