<ng-template
  #checkboxTemplate
  let-checkbox="checkbox"
  let-parent="ngParentObject"
>
  <li>
    <checkbox
      class="{{ checkbox?.class }}"
      [(ngModel)]="checkbox.checked"
      (ngModelChange)="onChange($event, checkbox)"
      [ngModelObject]="checkbox"
      [readonly]="readonly"
      [disabled]="checkbox?.disabled"
      [ngParentObject]="parent"
    >
      <div [innerHtml]="checkbox.label"></div>
      <div [innerHtml]="checkbox?.content"></div>
    </checkbox>
    <ul *ngIf="checkbox.children && checkbox.children.length">
      <ng-container *ngFor="let child of checkbox.children">
        <ng-container
          *ngTemplateOutlet="
            checkboxTemplate;
            context: {
              checkbox: child,
              ngParentObject: checkbox,
            }
          "
        ></ng-container>
      </ng-container>
    </ul>
  </li>
</ng-template>
<ul #list>
  <ng-container *ngFor="let checkbox of _model">
    <ng-container
      *ngTemplateOutlet="
        checkboxTemplate;
        context: {
          checkbox: checkbox,
          ngParentObject: checkbox,
        }
      "
    ></ng-container>
  </ng-container>
</ul>
